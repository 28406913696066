
.profil{

.form-register-label{
    margin-top: 15px;
    margin-bottom: 15px;
}


.form-control{
    margin-top: 15px;
    margin-bottom: 15px;
}
.form-register{
input{
    border-radius: 20px;
    border-width: 1px;
    width: 100%;
}
}
.form-register-label{
    font-family: 'HelveticaNeue';
    font-size: 13px;
    line-height: 16px;
    color: #929191;
    font-weight: bold;
label{
    margin-bottom: 0px;
}
}
.btn{
    font-weight: bolder;
    border-radius: 20px;
    width: 100%;
    font-size: 13px;
}

.btn-connexion{
    background-color: #BE9E54;
    border-color: #BE9E54;
}
.btn-open-account{
    background-color:white;
    border-color: #3C1053;
    color:#3C1053;
}
.subtitle{
    color:#3C1053;
    font-family: 'PlantagenetCherokee';
    font-size: 13px;
    line-height: 14px;
}
a{
    color:#929191;
}
a:hover{
   text-decoration: none;
   font-weight: bold;
   color: #929191;
}

.title-personal-information {
    height: 100px;
    color: white;
    display: flex;
}
.title-personal-information .row{
    margin: auto;
    width: 100%;
}
.profil-informations-perso-background {
    background-color: #3C1053;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.profil-logo {
    border-bottom: solid 1px;
    border-color: #ced4da;
.logo{
    margin: 20px;
}
}
.menu-left {
    height:130vh;
    border-right: solid 1px;
    border-color: #ced4da;
}
.profil-container {
    height: 100vh;
}

.profil-informations-perso {
    // margin: 100px;
    margin: auto;
}

.profil-title {
    margin: 30px;
    font-family: 'HelveticaNeue';
    font-size: 30px;
    line-height: 37px;
}
.profil-subtitle {
    font-family: 'HelveticaNeue';
    font-size: 16px;
}
.profil-background {
    background-color: #f9f9f9;
}
.personal-information-background {
    background-color: white;
    height: 50vh;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
}

.profil-password-visibility{
    visibility: hidden;
}


@media (max-width: 1199px) {

.profil-informations-perso{
 margin: 0px;

}


}

























}

