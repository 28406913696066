body {
    background-color: lightgray;
}
// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

@import "global";
@import "connexion";
@import "profil";
@import "user";
//font
@import "fonts";
