@font-face {
    font-family: 'HelveticaNeue';
    src: url('./fonts/HelveticaNeue.ttf') format('truetype');
    font-weight: normal;
  }
@font-face {
    font-family: 'PlantagenetCherokee';
    src: url('./fonts/PlantagenetCherokee.ttf') format('truetype');
  }
@font-face {
    font-family: 'HelveticaNeue';
    src: url('./fonts/HelveticaNeue-Bold.ttf') format('truetype');
    font-weight: bold;
  }

