.register{
    p.loginCodeFormDescription {
        color: #191D1F;
        font-family: "Plantagenet Cherokee";
        font-size: 11px;
    }
    .form-control{
        border-radius: 20px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .form-register{
        input{
            border-radius: 20px;
            border-width: 1px;
            width: 100%;
        }
        ul{
            li{
                color:red;
            }
        }
    }
    .form-register-label{
    label{
        margin-bottom: 0px;
    }
    }
    .btn{
        font-weight: bolder;
        border-radius: 20px;
        max-width: 100%;
        font-size: 13px;
    }

    .btn-connexion{
        background-color: #BE9E54;
        border-color: #BE9E54;
    }
    .btn-open-account{
        background-color:white;
        border-color: #3C1053;
        color:#3C1053;
    }
    .refresh-link {
        color: #929191;
        background-color: transparent;
        border: none;
        font-size: 13px;
        font-weight: bold;
    }
    .subtitle{
        color:#3C1053;
        font-family: 'PlantagenetCherokee';
        font-size: 13px;
        line-height: 14px;
    }
    a{
        color:#929191;
    }
    a:hover{
       text-decoration: none;
       font-weight: bold;
       color: #929191;
    }

    .right-part{
        height: 100vh;
        background-image: url("/images/tour_eiffel_register.jpg");
        background-size: cover;

    }
    .register-title{
        font-family: 'HelveticaNeue';
        font-weight: bold;
        font-size: 30px;
        line-height: 37px;
    }
    .register-subtitle-check-mail-sms{
        p{
            font-family: 'HelveticaNeue';
            font-weight:bold;
            font-size: 13px;
            line-height: 12px;
            color:#929191;
        }

    }
    .form-register-label{
        font-family: 'HelveticaNeue';
        font-size: 13px;
        line-height: 16px;
        color: #929191;
        font-weight: bold;
    }
    .form-register{
        p{
            font-family: 'HelveticaNeue';
            font-size: 13px;
            line-height: 12px;
            color:#929191;

        }

    }
    .register-login-container{
        height: 100vh;
    }
    .logo{
        margin: 20px;
    }

    .footer{
        margin: 20px;
    }

    .label-password-login, .label-password-login, .label-email-login, .label-code-login{
        font-family: 'HelveticaNeue';
        font-size: 13px;
        line-height: 16px;
        color:#929191;
        font-weight: bold;
    }

    .CGU-mentions-contact{
        font-size: 13px;
        margin-top: 40px;
    }

    .icon-register{
        position: absolute;
        top: 21px;
        left: 30px;
        width: 1.25em;
        height: 1.25em;
        fill:#3C1053 !important;

    }

    input.form-control-name,  input.form-control-email, input.form-control-phone, input.form-control-key {
        padding-left: 50px;
    }

    input.form-control-padlock{
        padding-left: 43px;
    }

    .eye-password{
        padding-left: 43px;
    }

    p.yourPhoneTitle {
        color: #929191;
        font-family: "HelveticaNeue";
        font-size: 13px;
        font-weight: bold;
    }

    p.yourPhoneNumber {
        color: #191D1F;
        font-family: "Plantagenet Cherokee";
        font-size: 13px;
        padding-left: 5px;
    }
}
