.user{

h1{
    a{
        color: black !important;
    }
}

margin-top: 50px;

    .form-register-label{
        margin-top: 15px;
        margin-bottom: 15px;
    }


    .form-control{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .form-register{
    input{
        border-radius: 20px;
        border-width: 1px;
        width: 100%;
    }
    }
    .form-register-label{
        font-size: 13px;
        line-height: 16px;
        color: #929191;
    label{
        margin-bottom: 0px;
    }
    }
    .btn{
        font-weight: bolder;
        border-radius: 20px;
        min-width: 10rem;
        min-height: 2.5rem;
        font-size: 13px;
    }
    .btn-compte{
        color:#3C1053;
        background-color:white;
        border-color: #3C1053;
    }

    .btn-connexion{
        background-color: #BE9E54;
        border-color: #BE9E54;
    }
    .btn-open-account{
        background-color:white;
        border-color: #3C1053;
        color:#3C1053;
    }
    .subtitle{
        color:#3C1053;
        font-family: 'PlantagenetCherokee';
        font-size: 13px;
        line-height: 14px;
    }
    a{
        color:#929191;
    }
    a:hover{
       text-decoration: none;
       font-weight: bold;
       color: #929191;
    }

    .title-personal-information {
        height: 100px;
        color: white;
        display: flex;
    }
    .title-personal-information .row{
        margin: auto;
        width: 100%;
    }
    .profil-informations-perso-background {
        background-color: #3C1053;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .profil-logo {
        border-bottom: solid 1px;
        border-color: #ced4da;
    .logo{
        margin: 20px;
    }
    }
    .menu-left {
        height:130vh;
        border-right: solid 1px;
        border-color: #ced4da;
    }
    .profil-container {
        height: 100vh;
    }

    .profil-informations-perso {
        // margin: 100px;
        margin: auto;
    }

    .profil-title {
        margin: 30px;
        font-family: 'HelveticaNeue';
        font-size: 30px;
        line-height: 37px;
    }
    .profil-subtitle {
        font-family: 'HelveticaNeue';
        font-size: 16px;
    }
    .profil-background {
        background-color: #f9f9f9;
    }
    .personal-information-background {
        background-color: white;
        height: 50vh;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .profil-password-visibility{
        visibility: hidden;
    }
    .table{
        thead{
        background-color:#3C1053;
        color:white;
        }
    }
    .search-form{
        input{
            border-radius: 20px;
            border-width: 1px;
        }
    }

    .user-action-picto {
        min-width: 30px;
    }

    .img-user-show{
        width: 23px;
    }

    .search-form{
        form{
            input{
                margin: auto;
                padding-left: 50px;
                background-image: url(/images/picto/search.png);
                background-repeat: no-repeat;
                background-size: 20px;
                background-position-x: 15px;
                background-position-y: 8px;
                height: 40px;
                width: 350px;
            }

        }
    }

    .card-title{
        background-image: url(/images/picto/Groupuser.svg);
        background-repeat: no-repeat;
        background-position: right;
    }

h1{
    margin-top: 50px;
    margin-bottom: 50px;
}

    //responsive

    @media (max-width: 1199px) {

    .profil-informations-perso{
     margin: 0px;

    }
    }

    .btn-large{
        width:100%;
    }

    .icon-plus{
        background-image: url(/images/plus.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        position: absolute;
        bottom: 5px;
        right: 11px;
        height: 30px;
        width: 30px;
    }

    .icon-moins{
        background-image: url(/images/moins.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        position: absolute;
        bottom: 5px;
        right: 11px;
        height: 30px;
        width: 30px;
    }
}

//recherche avancée

.nav-pills .nav-link{
    color: #191D1F;
    font-family: "HelveticaNeue";
    font-size: 13px;
    font-weight: bold;
}

.table-maps-recherche-avancee{
    border-collapse: separate;
    border-spacing: 0 15px;
    box-shadow: none;
}


.fiche-recherche-avancee{
    border-radius: 20px;
    background-color: #BE9E54;
    color: white !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "HelveticaNeue";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
}

.tr-maps-recherche-avancee{
  height: 120px;
  width: 358px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(195,195,195,0.3);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #3C1053;
    border-radius: 20px;
}

.nav-pills{
    border: 1px solid #191D1F;
    border-radius: 20px;
}

.nav-pills .nav-item{
    margin: 0px;
}

.recherche-avancee{
    height: 36px;
    color: #191D1F;
    font-family: 'HelveticaNeue';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 37px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.titre-form-recherche-avancee{
    font-family: 'HelveticaNeue';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.11px;
    line-height: 19px;
    background-color: #3C1053;
    color: white;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.form-recherche-avancee{
    border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(195,195,195,0.3);
}

.nbr-result-recherche-avancee{
    padding-top: 30px;
    padding-bottom: 30px;
    color: #191D1F;
    font-family: 'HelveticaNeue';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(195,195,195,0.3);

}

#advanced_search_addCondition{
    display: none;
}

.searchFilter{
    width: 179px;
    border: 1px solid #3C1053;
    border-radius: 20px;
   text-align: center;
   margin-top: 30px;
   margin-bottom: 30px;
   color: #3C1053;
   font-family: "HelveticaNeue";
   font-size: 13px;
   font-weight: bold;
   letter-spacing: 0;
   line-height: 16px;
   text-align: center;
   padding-top: 10px;
   padding-bottom: 10px;
}

.deleteCondition{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.reinitialise-research{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    img{
        width: 32px;
    }

}

.button-modify-research{
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #FFFFFF;
    font-family: "HelveticaNeue";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    border-radius: 20px;
    background-color: #BE9E54;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

#advanced_search_rechercher{
    height: 40px;
    width: 160px;
    box-shadow: 0 10px 20px 0 rgba(190,158,84,0.3);
    border-radius: 20px;
    background-color: #BE9E54;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #FFFFFF;
    font-family: "HelveticaNeue";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
}

.addCondition{
    border: 1px solid #3C1053;
    border-radius: 20px;
    background-color: #3C1053;
    color: #FFFFFF;
    font-family: "HelveticaNeue";
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    padding: 10px;
    padding-right: 30px;
}

#advanced_search_valider{
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: #191D1F;
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.11px;
    line-height: 19px;
    text-align: center;
    padding: 10px;
    text-decoration: underline;
}

.link-recherche-avancee{
    color: #191D1F !important;
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.11px;
    line-height: 19px;
    text-decoration: underline;
}

.advanced_search{
    border: 1px solid #EBEBEB;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.05);
    background: url(/images/chevron-advanced-search.svg) no-repeat scroll 257px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 40px;
    width: 281px;
    font-family: "PlantagenetCherokee";
    font-size: 13px;
    color: #191D1F;
    padding-left: 12px;
}

.wording-egal{
    color: #191D1F;
    font-family: "PlantagenetCherokee";
    font-size: 11px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: justify;
}


.tooltipCust {
    position: relative;
    display: inline-block;

  }

  .tooltipCust .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #3C1053;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 33px;
    left: 22px;
  }

  .tooltipCust:hover .tooltiptext {
    visibility: visible;
  }
  .tooltiptext{
    color: #FFFFFF;
    font-family: "HelveticaNeue";
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 10px;
    text-align: center;
  }

  .nbr-resultat{
    color: #191D1F;
    font-family: "HelveticaNeue";
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 36px;
  }


  .form-recherche-avancee{
      label{
        color: #929191;
        font-family: "HelveticaNeue";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
      }
      option{
        color: #191D1F;
        font-family: "PlantagenetCherokee";
        font-size: 13px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: justify;
      }
  }

  .advanced-research-result-nbr{
    text-align: left;

  }


  .and-wording{
    color: #191D1F;
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.11px;
    line-height: 19px;
  }

  .buttonCsv{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    color: #191D1F;
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.11px;
    text-align: right;
    background-image: url(/images/picto-csv.svg);
    background-repeat: no-repeat;
    background-size: 77px;
    background-position-x: 116px;
    background-position-y: -4px;
    width: 180px;
    padding-right: 56px;
    height: 53px;
    line-height: 16px;
    outline: 0 !important;


  }

  .buttonCsv:focus{
    outline: 0 !important;
  }

  .csv-form{
      form{
        outline: 0 !important;
      }
  }

//recherche avancée
