.no-padding{
    padding-left: 0px !important;
    padding-right: 0px !important;

}
.margin-auto{
    margin: auto;
}

.header{
    top:0;
    left: 0;
    right: 0;
    border-bottom: solid 1px;
    border-color: #ced4da;
    background-color: white;
    position: fixed;
    z-index: 100;
}

.navbar{
    position: fixed !important;
    left: 0;
    top :100px;
    height: calc(100vh - 100px);
    min-width: 100px;
    border-top: solid 1px #ced4da;
    border-right: solid 1px #ced4da;
    padding: 0px !important;
    z-index: 30;
}

.menu-navbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  .menu-navbar {
    -ms-overflow-style: none;
  }

.menu-navbar{
    overflow: scroll;
    height: 100%;
    a{
    color: #3C1053 !important;
    text-decoration: none;
    }
    .done{
        .row{
            .icon-navbar{
                fill: #A1D096;
                stroke: #A1D096;
            }
        }
    }
    .picto-menu{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .current-page{
        padding: 10px;
        border-left: solid 5px #BE9E54;
        color: #BE9E54;
                 .icon-navbar{
             fill: #BE9E54;

         }
        .svg-color{
            stroke: #BE9E54;
        }
        .drawer-title{
            a{
            color:#BE9E54 !important;
            }
            font-weight: bold;
        }
    }
    .page{
        padding: 10px;
        border-left: solid 5px #f8f9fa;
    }

}

.container-position{
    margin-top: 100px;
    margin-left: 100px;
    background-color: #F9F9F9;
    min-height: 95vh;
}

@media (max-width: 1199px) {

    .container-position{
        margin-top: 100px;
        margin-left: 0px;
        background-color: #F9F9F9;
        min-height: 95vh;
    }
}

table{
    .table-user{
      padding: 1.5rem;
      vertical-align: middle;
    }
}


table{
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 0 10px 20px 0 rgba(110, 110, 110, 0.3);

 thead{


     tr{
         text-align:center;
        .border-left{
            border-top-left-radius: 5px;
            -moz-border-top-left-radius: 5px;
            }
        .border-right{
            border-top-right-radius: 5px;
            -moz-border-top-right-radius: 5px;
            }
         th{
            font-family: 'HelveticaNeue';
            font-size: 16px;
            line-height: 19px;
         }
     }
 }
 tbody{
    color: #191D1F;
    font-family: "PlantagenetCherokee";
    font-size: 13px;
    line-height: 14px;
    text-align: justify;
     tr{
        td{
            .border-left{
                border-top-left-radius: 5px;
                -moz-border-top-left-radius: 5px;
                }
            .border-right{
                border-top-right-radius: 5px;
                -moz-border-top-right-radius: 5px;
                }
         }
         text-align:center;
        td{
            vertical-align: middle !important;
            a{
                img{
                    height: 25px;
                    width: 21px;
                    margin: 2px;
                }
            }
        }
     }
 }
}

.pagination{
    @extend .justify-content-center;
    .current{
        background-color: #3C1053;
        color:white;
        a{
            color:white;
        }
    }
    .first{
        @extend .d-none;
    }
    .next{
    }
    .last{
        @extend .d-none;
    }
    span{
        @extend .page-link;
        margin: 1px;
    }
    span:hover{
        color: black;
        a{
            color:black;

        }
    }
}
.logo{
    img{
        // width: 3.75rem;
        // height: 2.125rem;
        width: 8rem;
        height: 5rem;
    }

}
.CGU-mentions-contact{
    a{
        text-decoration:none;
        color:#929191;
    }
    a:hover{
        text-decoration: none;
        font-weight: bold;
        color: #929191;
     }
}
.card-header{
    background-color: #3C1053;
}

.btn-connexion{
    background-color: #BE9E54;
    border-color: #BE9E54;
    box-shadow: 0 10px 20px 0 rgba(190,158,84,0.3);
}
.btn-delete{
    border-color:red !important;
    color:red !important;
    background-color: transparent;
    border: 1px solid transparent;
 }

 .btn-delete:hover{
     background-color: transparent !important;
 }

.btn{
    // box-shadow: 0 10px 20px 0 rgba(190,158,84,0.3);
    font-weight: bolder;
    border-radius: 20px;
    min-width: 10rem;
    min-height: 2.5rem;
    font-size: 13px;
}

// .btn-delete{
//     .btn{
//     border-color:red !important;
//     color:red !important;
//     }
// }

.btn-close{
    border-color:#3C1053 !important;
    color:#3C1053 !important;
}
.accordion{
    .btn-link{
        text-align: left;
        color:white;
        text-decoration: none;

    }
}

.profil-logo{
align-items: center;
height: 100px;
}

.nav-item{
    margin: 20px;
}

.border-nav-item{
    border-left-style: solid;
    border-color: #BE9E54;
    border-width: 4px;

}

h1{
    // margin-left: 30px;
    font-size: 1.875rem;
    line-height: 2.3125rem;
    text-align: left;
    font-weight: bold;
    font-family: 'HelveticaNeue';
    // margin-bottom: 50px;

}

.form-control-checkbox{
    margin-top: 15px;
    margin-bottom: 15px;
    input{
        margin: 10px;
    }
}
.user-subtitle{
    font-size: 11px;
    line-height: 12px;
    margin-top: 1px;
}
.form-error{
    color:red !important;
}
.form-subtitle{
    font-family: 'PlantagenetCherokee';
    font-size: 13px;
    line-height: 14px;
    color: #3C1053;
    margin-top: 20px;
    margin-bottom: 20px;
}
.form-register-label{
    label{
        font-size: 13px;
        line-height: 16px;
        font-family: 'HelveticaNeue';
        font-weight: bold;
    }
}

.icon-navbar{
    width: 1.5em;
    height: 1.5em;
    fill: #3C1053;

}

.icon-user-disabled{
    width: 1em;
    height: 1em;
    fill: #3C1053;

}

.icon-navbar-admin{
    width: 1.25em;
    height: 1.25em;
    fill: #3C1053;
}

.icon-user-show{
    width: 0.5em;
    height: 0.5em;
}

.icon-deco{
    width: 5em;
    height: 5em;
}
.svg-color{
    stroke: #3C1053;
}

.icon-show{
    width: 2em;
    height: 2em;
}

.icon-title{
    width: 5em;
    height: 5em;
}

.picto-user{
    margin-top: 40px;
    margin-bottom: 40px;
}

.picto-documents{
    margin-bottom: 40px;
}

//responsive

@media (max-width: 960px) {
    .container-position{
        position: inherit;
        top: 98px;
        left: 0px;
        right: 0;
        bottom: 0;
    }

}

.rugieri-fixed-bottom {
    margin-top: 20px;
}

.hidden {
    display: none;
}

// .user{
//     background-color:white;
// }

form{
    padding:15px;
}
 .container-background {
     background-color:#F9F9F9;
 }

.background-white{
    background-color:white;
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgba(195,195,195,0.3);
}
.user{
    padding-bottom: 50px;
}

.btn-open-account{
    display: table;
        span{
            display: table-cell;
        }
}

.disabled-user{
    height: 100vh;
}

.navbar{
    background-color: #FFFFFF !important;
}

.background-profil{
    background-color: white;
    box-shadow:  0 10px 20px 0 rgba(43, 43, 43, 0.3);
    border-radius: 6px !important;
}
.title-personal-information{
    font-family: 'HelveticaNeue';
    font-weight: bold;
    a{
        color:white !important;

    }
}

.form-control{

        color: #191D1F;
        font-family: "Plantagenet Cherokee";
        font-size: 13px;
        line-height: 14px;
        text-align: justify;
}

.form-register-sms{
    padding-left: 50px;
}

.form-register-label{
    .form-control{
        color:#191D1F;
        font-family: "Plantagenet Cherokee";
        font-size: 13px;
        line-height: 14px;
        text-align: justify;
    }
    p{
        color: #191D1F;
        font-family: "Plantagenet Cherokee";
        font-size: 11px;
        line-height: 12px;
        text-align: justify;
    }
}

.register{
    form{
        padding: 0;
    }
}

.icon-login{
    position: absolute;
    top: 50px;
    left: 30px;
    width: 1.25em;
    height: 1.25em;
    fill:#3C1053 !important;
}
.mail-login{
    position: absolute;
    top: 50px;
    left: 30px;
    width: 1.25em;
    height: 1.25em;
    fill:#3C1053 !important;
}

.user-search{
    position: absolute;
    top: 26px;
    left: 31px;
    width: 1.25em;
    height: 1.25em;
    fill:#3C1053 !important;
}

.accordion{
    .card{
        margin-bottom: 20px;
        border-radius: 6px !important;
        box-shadow: 0 0 20px 0 rgba(195,195,195,0.3);
    }
    .card-header{
        min-height: 79px;
    }
}

.btn-refus{
    background-color: transparent;
    border-color: #6c757d;
    color: #212529;

}

.btn-refus-cgu {
    background-color: transparent;
    border-color: #6c757d;
    color: #3C1053;
}


.background-user-disable{
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgba(195, 195, 195, 0.3);
    padding: 50px;
}

.disabled-user{
    background-color: #f9f9f9;
}
.disabled-user-cgu{
    background-color: #f9f9f9;
    min-height: 150vh;
        .picto-croix{
            margin: 20px;
            .icon-croix{
                width: 3em;
                height: 3em;
            }
        }
}

.disabled-user{

    .border-mail{
        border: solid 1px;
        border-radius: 20px;
        border-color:#C3C3C3;
        padding-top: 5px;
        padding-bottom: 7px;
        padding-right: 70px;

    }
    a{
        color: #191D1F;
        font-family: 'HelveticaNeue';
        font-weight: bold;
        font-size: 13px;
        line-height: 14px;
        text-align: justify;
    }
    .img-logo{
        // width: 70px;
        min-height: 90px;
    }
    .picto-croix{
        padding: 40px;
        position: fixed;
        top: 0;
        right: 0;
        .icon-croix{
            width: 3em;
            height: 3em;
        }

    }
}

.disabled-user-title{
    font-size: 30px;
    font-family: 'HelveticaNeue';
    color: #191D1F;
    font-weight: bold;
    line-height: 37px;
    text-align: center;

}

.disabled-user-text{
    color: #191D1F;
    font-family: "Plantagenet Cherokee";
    font-size: 13px;
    line-height: 14px;
    text-align: justify;
}

.ruggieri-logo{
    width: 9rem;
    height: 5rem;

}

.title-cgu{
    font-size: 30px;
    font-weight: bold;
    line-height: 37px;
    font-family: 'HelveticaNeue';
}

.registration-cgu{
    form{
        padding: 0;
    }
}

.mt-200{
    margin-top: 200px;
}

.background-cgu{
    background-color:white;
    box-shadow: 0 0 20px 0 rgba(195, 195, 195, 0.3);
    border-radius: 6px;

}

.collase-menu-responsive{
    position: fixed;
    z-index: 10000;
    right: 0;

}

.menu-responsive-border{
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(195, 195, 195, 0.3);
    max-height: 500px;
    overflow: scroll;
}

.identityRectoFile{
    color: #3C1053;
    font-size: 20px;
}
.identityVersoFile{
    color: #3C1053;
    font-size: 20px;
}
.passeportRectoFile{
    color: #3C1053;
    font-size: 20px;
}
.scanCertifFile{
    color: #3C1053;
    font-size: 20px;
}

.info-caces{
    color: #BE9E54;
}


  .user-subtitle-form{
    font-size: 11px;
    // color: #3C1053;
    line-height: 12px;
    margin-bottom: 50px;
}

.btn-formulaire{
    margin-left: 5px;
}

.ruggieri-caces{
    width: 1.5em;
    height: 1.5em;
    fill: #BE9E54;
}
.info-caces-text{
    padding-right: 0;
    font-size: 13px;
    font-weight: bold;
}

.tabletir{
 input{
     min-width: 60px;
 }
 label{
     min-width: 60px;
 }
}
.form-register-label {
    p{
        font-family: "regular";
    }
}